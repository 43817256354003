import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';
import {cmptInputClear} from '../../util/cmpt-common-features';
import * as keyPress from '../../util/key-press-helpers';
import * as store from '../../util/store';

/**
 * Performs a search. Listens for results.
 * @class Sidekick.Search
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {Number} [ctx.limit=20] - Count of items to show before view all appears
 * @param {String[]} [ctx.searchTypes=type-ahead] - An array of options of how to search. Defaults to type-ahead.
 *        'type-ahead' Type ahead search
 *        'search-on-enter' Search when pressing enter
 * @param {String} [ctx.placeHolderText=Search] - Default text put in the search box
 * @param {Function} i18n - translation function
 * @emits header.search - Fired when a search is done. Passes the search value
 * @emits header.search.item-clicked - Fired when a search result is clicked
 * @listens header.search.results - Array of HTML to display in the result list.
 */
export default function(elem, attrs, ctx, i18n) {
	const listEmpty = dom.parse(`
		<ul id="sk-search-results" class="sk--menulist" role="listbox" aria-live="polite" aria-labelledby="sk-search-results-title">
			<li class="sk-list-empty" role="option" tabindex="0">${i18n('No Results Found')}</li>
		</ul>
	`);
	const limit = ctx.limit || 20;

	/////
	// find dom elements that we care about
	//
	const [domSearchText] = elem.find('.search');
	const [domResponsiveSearchText] = elem.find('.sk-search-box .search');
	const [domClearBtn] = elem.find('.ss-ctrl-clear');
	const [domMenu] = elem.find('.sk--menu');
	const [domResultsContainer] = elem.find('.sk--search-content');
	const [domLoader] = elem.find('.sk--loader');
	const [domSearchBox]=elem.find('.sk-search-box');
	const { responsiveTopNav } = store.get('sk-config');

	/////
	// handle iniital state and data changes
	//
	domSearchText.attr('placeholder', ctx.placeHolderText || i18n('Search'));
	domSearchText.attr('aria-label', ctx.placeHolderText || i18n('Search'));
	events.on('header.search.results', updateResults);

	/////
	// listen for events and take action
	//
	elem.on('click', evt => evt.stopPropagation());
	if(responsiveTopNav){
		const domSearchContainer = document.getElementsByClassName('sk-search')[0];
		const domSearchResponsiveIcon=dom.parse(`<button class="control fa-li-search-before sk-search-responsive-icon" aria-controls="sk--search-menu" aria-pressed="false" aria-label="Search"></button>`);
		domSearchContainer.classList.add('sk-header-container-responsive');
		domSearchContainer.insertBefore(domSearchResponsiveIcon._, domSearchBox._);
		domSearchResponsiveIcon.on('click', function() {
			domSearchBox.toggleClass('sk-search-box-open');
		});
	}
	dom.element(document).on('click', reset);
	events.on('header.search.reset', reset);
	events.on('sk-menu-open', menuEl => {
		if (menuEl !== elem) { reset(); }
	});
	dom.element(window).on('blur', function () {
		if (document.activeElement != null && document.activeElement.tagName === 'IFRAME') { reset(); }
	});

	cmptInputClear(domSearchText, domClearBtn, true);
	domClearBtn.on('click', reset);
	domSearchText.on('keydown', closeOnEscape);
	domSearchText.on('focus', selectInputText);

	// hook up searching options
	if (typeof ctx.searchTypes === 'string') {
		ctx.searchTypes = [ctx.searchTypes];
	} else if (ctx.searchTypes == null || ctx.searchTypes.length === 0) {
		ctx.searchTypes = ['type-ahead'];
	}
	if (ctx.searchTypes.includes('search-on-enter')) {
		domSearchText.on('keydown', searchOnEnter);
	}
	if (ctx.searchTypes.includes('type-ahead')) {
		// if they are doing search-on-enter as well, let that take priority
		if (!ctx.searchTypes.includes('search-on-enter')) {
			domSearchText.on('keydown', typeAheadOnEnter);
		}
		domSearchText.attr('aria-expanded', 'false');
		domSearchText.attr('aria-autocomplete', 'list');
		domSearchText.attr('aria-owns', 'sk-search-results');
		domSearchText.on('input', search);
		domSearchText.on('click', () => {
			events.emit('sk-menu-open', elem);
			search();
		});
		elem.on('keydown', focusInputOnEscape);
		elem.on('keydown', selectResultOnArrow);
	}

	/////
	// update function for when help list changes
	//

	/**
	 * Resets button and menu state.
	 */
	function reset() { dom.rAF(search); }

	/**
	 * Restore focus to search box, selecting the value if populated
	 */
	function selectInputText({target}) {
		if (target.value === '') { return; }
		target.setSelectionRange(0, target.value.length);
	}

	/**
	 * Restore focus to search box, selecting the value if populated
	 */
	function focusInputOnEscape(evt) {
		// bail out if this was not the ESCAPE key
		if (!keyPress.isEscape(evt)) { return; }
		if (document.activeElement === domSearchText._) { return; }
		domSearchText._.focus();
	}

	/**
	 * Closes the menu when escape is pressed
	 */
	function closeOnEscape(evt) {
		// bail out if this was not the ESCAPE key
		if (!keyPress.isEscape(evt)) { return; }
		reset();
	}

	/**
	 * Submit the search value to request type-ahead results
	 */
	function typeAheadOnEnter(evt) {
		// bail out if this was not the ENTER key
		if (!keyPress.isEnter(evt)) { return; }
		events.emit('header.search.enter-pressed', domSearchText._.value);
	}

	/**
	 * Changes focus to a typeahead search result
	 */
	function selectResultOnArrow(evt) {
		// bail out if this was not the UP or DOWN key
		if (!keyPress.isUp(evt) && !keyPress.isDown(evt)) { return; }

		const optionElems = domResultsContainer
			.find('[role=option]')
			.map(o => o._);
		if (optionElems.length === 0) { return; }

		evt.preventDefault();

		if (domSearchText.hasFocus()) {
			// select the first (down) or last (up) result
			const idx = keyPress.isUp(evt) ? optionElems.length - 1 : 0;
			optionElems[idx].focus();
			return;
		}

		const focusedOptionIdx = optionElems.indexOf(document.activeElement);

		// bail out if the currently focused element is not a search result
		if (focusedOptionIdx === -1) { return; }

		// restore focus to search box when moving beyond first or last result
		if (focusedOptionIdx === 0 && keyPress.isUp(evt)) { return domSearchText._.focus(); }
		if (focusedOptionIdx === optionElems.length - 1 && keyPress.isDown(evt)) { return domSearchText._.focus(); }

		const nextIdx = keyPress.isUp(evt) ? focusedOptionIdx - 1 : focusedOptionIdx + 1;
		optionElems[nextIdx].focus();
	}

	/**
	 * Makes a search only when enter is pressed.
	 */
	function searchOnEnter(evt) {
		// bail out if this was not the ENTER key
		if (!keyPress.isEnter(evt)) { return; }

		domResultsContainer.empty();

		const searchLength = domSearchText._.value.length;
		domClearBtn.css('display', searchLength > 0 ? 'block' : 'none');
		if (searchLength > 0) {
			events.emit('header.search', domSearchText._.value);
			domSearchText._.value = '';
			domClearBtn.addClass('sk-hidden');
		}

		// unfocus the search box, also fixes IE10 weirdness
		if (document.activeElement) { document.activeElement.blur(); }
	}

	/**
	 * Makes a search.
	 * @emits header.search - Passes the search value
	 */
	function search() {
		domResultsContainer.empty();
		if (!domSearchText.hasFocus()) {
			domMenu.removeClass('ss-menu-open');
			domSearchText.attr('aria-expanded', 'false');
			if(responsiveTopNav){
				domSearchBox.removeClass('sk-search-box-open');
				domResponsiveSearchText._.value = '';
				domClearBtn.addClass('sk-hidden');
			}
			return;
		}

		var searchLength = domSearchText._.value.length;
		if (searchLength > 0) {
			domMenu.addClass('ss-menu-open');
			domSearchText.attr('aria-expanded', 'true');
			domLoader.css('display', 'block');
			events.emit('header.search', domSearchText._.value);
		} else {
			domMenu.removeClass('ss-menu-open');
			domSearchText.attr('aria-expanded', 'false');
			events.emit('header.search.no-query');
		}
	}

	/**
	 * Updates the result list.
	 * @param  {Array} results - Accepts an array of HTML to display in the result list.
	 * @listens header.search.results - Array of HTML to display in the result list.
	 */
	function updateResults(results) {
		domLoader.css('display', 'none');
		if (!domSearchText.hasFocus()) { return; }

		const resultsIsArray = results instanceof Array;

		if (results == null || (resultsIsArray && results.length === 0)) {
			domResultsContainer.children(listEmpty);
			return;
		}

		if (typeof results === 'string') {
			elem.addClass('sk--hide-instruction');
			domResultsContainer.children(dom.parse(results));
			domMenu.addClass('ss-menu-open');
		} else {
			// process the list of blocks to display
			const resultsList = dom.parse(`<ul id="sk-search-results" class="sk--menulist" role="listbox" aria-live="polite" aria-labelledby="sk-search-results-title"></ul>`);
			resultsList.children(results.slice(0, limit).map(function (res, idx, lst) {
				const domRes = dom.parse(res);
				domRes.attr('role', 'option');
				domRes.attr('tabindex', '0');

				const searchResult = dom.parse(`<li role="presentation"></li>`);
				searchResult.append(domRes);

				searchResult.on('click', function (evt) {
					evt.stopPropagation();
					evt.preventDefault();
					domMenu.removeClass('ss-menu-open');
					events.emit('header.search.item-clicked', idx);
				});

				return searchResult;
			}));

			domResultsContainer.children(resultsList);
		}
	}
};
