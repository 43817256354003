import * as dom from '../../util/easy-dom';
import {cmptMenu} from '../../util/cmpt-common-features';
import * as store from '../../util/store';
import * as events from '../../util/event-bus';

/**
 * Displays notifications
 * @class Sidekick.Notifications
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {Function} i18n - translation function
 * @emits notification.delete
 * @emits notification.mark-as-read
 * @listens store notifications
 * @example Array of notifications
 * title: Notification Title
 * content: Notification Content
 * unread: Optional. Shows the unread dot
 * timestamp: Optional. Shows a timestamp
 * {
	title: 'this is notification 1',
	content: 'You got notified...',
	unread: true
}, {
	title: 'this is notification 2',
	content: 'You got notified... again!',
	timestamp: 'TODAY'
}
 */
export default function (elem, attrs, ctx, i18n) {
  const { responsiveTopNav } = store.get('sk-config');

  // Find DOM elements
  const domElements = {
    desktop: {
      cont: elem.find('.menulist')[0],
      menu: elem.find('.menu')[0],
      bell: elem.find('.control')[0],
      unreadBadge: elem.find('.control .sk--unread')[0],
      menuCloseIcon: elem.find('.ss-ctrl-clear')[0]
    },
    mobile: {
      cont: elem.find('.menulist-mobile')[0],
      menu: elem.find('.menu-notifications-mobile')[0],
      bell: elem.find('.control-responsive-notifications')[0],
      unreadBadge: elem.find('.control-responsive-notifications .sk--unread')[0],
      notificationBtn: elem.find('.control-responsive-notifications')[0],
    },
  };

  const domButtonElements = document.querySelectorAll('.notifications-btn');
  const domMenuElements = document.querySelectorAll('.menu-notications');

  if (responsiveTopNav) {
    domButtonElements.forEach((domButtonElement) => {
      domButtonElement.classList.add('sk-notification-responsive');
    });
    domMenuElements.forEach((domMenuElement) => {
      domMenuElement.classList.add('sk-notification-menu-responsive');
    });
    if (attrs.role === 'navitem') {
      domElements.mobile.notificationBtn.css('display', 'block');
      if (ctx.url != null) {
        domElements.mobile.bell.attr('target', ctx.target);
        events.on('notifications.unread-count', setUnreadBadge, true);
        return;
      }
      domElements.mobile.notificationBtn.on('click', (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        const displayMobileMenu = domElements.mobile.menu.css('display');
        const displayAction = displayMobileMenu === 'none'? 'block' : 'none';
        domElements.mobile.menu.css('display', displayAction);
      });
    }
  }

  // Handle initial state and data changes
  if (ctx.url != null) {
    domElements.desktop.bell.attr('target', ctx.target);
    events.on('notifications.unread-count', setUnreadBadge, true);
    return;
  }
  store.onValue('notifications', updateAllNotifications);

  // Listen for events and take action
  cmptMenu(elem, domElements.desktop.bell, domElements.desktop.menu);
  domElements.desktop.menuCloseIcon.on('click', () => events.emit('sk-menu-close', elem));
  handleMenuEvent(domElements.desktop);
  if(responsiveTopNav) {
    handleMenuEvent(domElements.mobile);
  }

  // Update function for when notification list changes
  function updateNotificationList(newCtx, elements) {
    // Maintain backward compatibility with ctx as an array
    if (Object.prototype.toString.call(newCtx) === '[object Array]') {
      newCtx = { items: newCtx };
    }

		// update our context object
		if (ctx.items !== newCtx.items) { ctx = newCtx; }

    // Update the unread count
    updateUnread(newCtx);
    const domNotificationsEmpty = dom.parse(`<li class="menulist-item notifications-empty"><div>${i18n('You have no notifications')}</div></li>`);

    // No items to display
    if (ctx.items.length === 0) { elements.cont.children(domNotificationsEmpty); return; }

    // Process the list of items to display
    elements.cont.children(ctx.items.map(item => {
      const el = dom.parse(`
        <li class="menulist-item ${item.unread ? 'unread' : ''}">
          <h4 class="subject">
            ${item.unread ? '<a class="mark-read" href="#" aria-label="mark as read"></a>' : ''}
            ${item.title}
          </h4>
          <div>${item.content}</div>
          ${item.timestamp ? `<small>${item.timestamp}</small>` : ''}
          <button type="button" class="remove fa-li-close" aria-label="remove notification"></button>
          </a>
        </li>
      `);

			el.find('.remove')[0].on('click', evt => {
				evt.preventDefault();
				evt.stopPropagation();
				events.emit('notification.delete', item);
			});

      if (item.unread) {
        el.find('.mark-read')[0].on('click', function (evt) {
          evt.preventDefault();
          evt.stopPropagation();
          events.emit('notification.mark-as-read', item);
          updateUnread(ctx);
        });
        el.on('click', function (evt) {
          evt.stopPropagation();
          events.emit('notification.mark-as-read', item);
        });
      }

			el.on('click', function (evt) {
				evt.stopPropagation();
				events.emit('notification.action', item);
			});

			return el;
		}));

    if (!elements.menu.hasClass('ss-menu-open')) {
      elements.cont.find('=a').forEach(a => a.attr('tabindex', '-1'));
      elements.cont.find('=button').forEach(b => b.attr('tabindex', '-1'));
    }
  }

  function updateAllNotifications(newCtx) {
    updateNotificationList(newCtx, domElements.desktop);
    if(responsiveTopNav) {
      updateNotificationList(newCtx, domElements.mobile);
    }
  }

  function updateUnread(ctx) {
    let unread = ctx.items.filter(n => n.unread === true).length;
    setUnreadBadge(unread);
  }

  function setUnreadBadge(unread) {
    elem.toggleClass('has-unread', unread > 0);
    domElements.desktop.unreadBadge.css('display', 'none');
    domElements.desktop.unreadBadge.removeClass('sk--max');
    if (unread > 0) {
      if (unread > 99) {
        domElements.desktop.unreadBadge.addClass('sk--max');
        unread = 99;
      }
      domElements.desktop.unreadBadge._.innerHTML = unread;
      domElements.desktop.unreadBadge.css('display', 'block');
    }
    if(responsiveTopNav) {
      domElements.mobile.unreadBadge.css('display', 'none');
      domElements.mobile.unreadBadge.removeClass('sk--max');
      if (unread > 0) {
        if (unread > 99) {
          domElements.mobile.unreadBadge.addClass('sk--max');
          unread = 99;
        }
        domElements.mobile.unreadBadge._.innerHTML = unread;
        domElements.mobile.unreadBadge.css('display', 'block');
      }
    }
  }

  function handleMenuEvent(elements) {
    events.on('sk-menu-close', function (e) {
      if (e !== elem) {
        return;
      }
      elements.cont.find('=a').forEach((a) => a.attr('tabindex', '-1'));
      elements.cont.find('=button').forEach((b) => b.attr('tabindex', '-1'));
    });
    events.on('sk-menu-open', function (e) {
      if (e !== elem) {
        return;
      }
      elements.cont.find('=a').forEach((a) => a.attr('tabindex', '0'));
      elements.cont.find('=button').forEach((b) => b.attr('tabindex', '0'));
    });
  }
}
