import * as tmpl from '../../.💩/sidekick-templates';
import { toDashCase, toCamelCase } from '../util/string-helpers';
import * as dom from '../util/easy-dom';
import * as store from '../util/store';
import * as events from '../util/event-bus';
import { i18n } from '../util/translations';

import header from './sidekick-header/header';
import nav from './sidekick-nav/nav';
import navItem from './sidekick-nav-item/nav-item';
import notifications from './sidekick-notifications/notifications';
import navItemSubmenu from './sidekick-nav-item-submenu/nav-item-submenu';
import userInfo from './sidekick-user-info/user-info';
import help from './sidekick-help/help';
import search from './sidekick-search/search';
import loader from './sidekick-loader/loader';
import orgSwitcher from './sidekick-org-switcher/org-switcher';
import appSwitcher from './sidekick-app-switcher/app-switcher';
import contactOrg from './sidekick-contact-org/contact-org';
import tooltip from './sidekick-tooltip/tooltip';
import pickOrgApp from './sidekick-pick-org-app/pick-org-app';
import pickOrg from './sidekick-pick-org/pick-org';
import pickApp from './sidekick-pick-app/pick-app';
import impersonatedUser from './sidekick-impersonated-user/impersonated-user';
import activityIndicator from './sidekick-activity-indicator/activity-indicator';
import navItemMegaMenu from './sidekick-nav-item-mega-menu/nav-item-mega-menu';
import sessionTimeout from './sidekick-session-timeout/session-timeout';
import siteErrors from './sidekick-site-errors/site-errors';
import skipNav from './sidekick-skip-nav/skip-nav';
import unsupportedBrowserAlert from './sidekick-unsupported-browser-alert/unsupported-browser-alert';/*cmptInject*/
import footer from './sidekick-footer/footer';
import audioeye from './sidekick-audioeye/audioeye';

/**
 * Loads the components
 * @module Sidekick.ComponentLoader
 * @memberOf Sidekick
 */

/**
 * Sidekick component list
 * @private
 */
const cmpt = {
	header, nav, navItem, notifications, navItemSubmenu, userInfo,
	help, search, loader, orgSwitcher, appSwitcher, footer, contactOrg,
	tooltip, pickOrgApp, pickOrg, pickApp, impersonatedUser,
	activityIndicator, navItemMegaMenu, sessionTimeout, siteErrors,
	skipNav, audioeye, unsupportedBrowserAlert/*cmptInject*/
};
var bootstrapAllInProgress = false;
var bootstrapComplete;

/**
 * Loads all the components
 * @function bootstrapAll
 * @instance
 */
export default function bootstrapAll() {
	bootstrapAllInProgress = true;
	bootstrapComplete = true;
	Object.keys(cmpt).forEach(c => bootstrap(c));
	bootstrapAllInProgress = false;
	if (!bootstrapComplete) { setTimeout(bootstrapAll, 0); }
}

function bootstrap(moduleNameCamel) {
	const moduleName = toDashCase(moduleNameCamel);
	var elems = document.querySelectorAll(`[sk-${moduleName}]`);

	for (let i = elems.length; i--;) {
		const elem = dom.element(elems[i]);

		// bootstrapping this element could add more elements
		// that also need to be bootstrapped, so inform the bootstrapper
		// that we are not done yet
		bootstrapComplete = false;

		// add the cmpt class to the array to signify that it has
		// been bootstrapped and should be bootstrapped again
		elem.addClass('sidekick').addClass(`sk-${moduleName}`);
		elem.attr(`sk-${moduleName}`, null);

		/////
		// bootstrap the element
		//

		// parse attrs
		const attrs = {};
		for (let j = elem._.attributes.length; j--;) {
			const attr = elem._.attributes[j];
			attrs[toCamelCase(attr.name)] = attr.value;
		}

		if (elem._.context != null) {
			initElement(elem, attrs, elem._.context);
		} else if (attrs.contextStore) {
			store.onValueOnce(attrs.contextStore, storeCtxElemInitializer(elem, attrs));
		} else {
			initElement(elem, attrs, null);
		}
	}

	function storeCtxElemInitializer(elem, attrs) {
		return (data) => {
			// init the element with the new data
			initElement(elem, attrs, data);
			if (!bootstrapAllInProgress) { bootstrapAll(); }
		};
	}

	function initElement(elem, attrs, ctx) {
		// clean out the existing element content
		const el = elem._;
		while (el.firstChild) { el.removeChild(el.firstChild); }
		// if we have a template for this component, mix with ctx and fill elem
		if (tmpl[moduleNameCamel]) {
			el.innerHTML = tmpl[moduleNameCamel](ctx, attrs, i18n);
		}
		// mark this element as initialized
		// uses rAF to allow css transitions on entering initialized state
		dom.rAF(() => elem.addClass(`sk-${moduleName}-initialized`));

		// run the component's controller method
		cmpt[moduleNameCamel](elem, attrs, ctx, i18n);
		dom.rAF(() => events.emit(`_${moduleName}-initialized`, elem, attrs, ctx));
	}
}
