import * as events from '../../util/event-bus';
import * as store from '../../util/store';
import * as env from '../../util/env';
import * as dom from '../../util/easy-dom';
import * as persist from '../../util/persistence';
import { toCamelCase } from '../../util/string-helpers';
import { glean } from '../../util/object-helpers';
import { isUrlUiModule } from '../../util/asset-loader';
import { handleVendorNavItemClick } from '../../util/vendorSSO-helpers';
import { getAccessToken } from '../../util/idm-helpers';

let httpGet = null;
let idmData = null;
let featureFlagsCC = null;
let i18n = null;
let elem = null;
let canViewOthers = null;
//Product ids of the apps for which `WageStream` access is provided
const WHITELISTED_WAGESTREAM_PRODUCTS = ['ABSMGMTSTAGE', 'ABSMGMT'];

// get the token and idmData from the sidekick, store in local vars
events.once('token-ready', ({ get }) => { httpGet = get; makePlatformNav(); }, { greedy: true });
events.once('view-others-permissions-checked', viewOthers => { canViewOthers = viewOthers; makePlatformNav(); }, { greedy: true });
events.once('feature-flags-ready-cc', flagsSvc => { featureFlagsCC = flagsSvc; makePlatformNav(); }, { greedy: true });
store.onValueOnce('idm-data', _idmData_ => { idmData = _idmData_; makePlatformNav(); });
// wait for nav component to send us the translations service instance
export default (_elem, _i18n) => { i18n = _i18n; elem = _elem; makePlatformNav(); };

// called multiple times but doesn't truly execute until all data is available (like Promise.all())
function makePlatformNav() {
	// if we don't have all the data we need yet, wait till we do
	if (httpGet == null) { return; }
	if (canViewOthers == null) { return; }
	if (featureFlagsCC == null) { return; }
	if (idmData == null) { return; }
	if (i18n == null) { return; }

	const automationNoPlatformNav = sessionStorage.getItem('automation-no-platform-nav') === 'true';
	if (automationNoPlatformNav) { return; }

	events.on('_location.path-change', () => dom.rAF(() => {
		const platformHasHighlight = elem.find('.sk--platform-menu.highlight').length > 0;
		elem.toggleClass('sk--platform-has-highlight', platformHasHighlight);
	}));

	// locate environment specific urls
	const flInternalProducts = store.get('fl-internal-product-ids') || [];
	const idm = store.get('idm');
	let envName = env.environmentFromIdmGatewayUrl(idm.idGatewayUrl);
	if (idm.product === 'FCQA') { envName = 'qa'; }
	const envValues = env[envName];
	const { ecSand, ssBaseUrl, dataExporterBaseUrl, vendorSSOApiUrl, rubricDesignerUrl, permissionServiceBaseUrl } = envValues;

	const { productId } = idmData;
	const pId = env.productIdFromMap(productId, envValues.productIdMap);

	// bailout if this is an internal product
	if (flInternalProducts.includes(pId)) { return; }

	const urlIsUiModule = isUrlUiModule(window.location.href);
	const orgId = glean(idmData, x => x.userContext.OrganizationId, '');

	const idmAdministrator = idmData.roles.includes('IdmAdministrator');
	const platPermissionToExclude = [
		'viewDownloadsMenuItem',
		'viewUserDownloads',
	];

	// the full extended nav (pre-permissions)
	const platformNavAll = [
		{
			title: i18n('System Configuration'),
			icon: 'fa-li-settings-sliders',
			routeMatcher: [`^/${pId}-useraccess`],
			children: [
				// {
				//     title: i18n('Manage User Access'),
				//     url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-useraccess/`,
				//     visible: hasPermittedAction('users', 'view')
				// }
			]
		}, {
			title: i18n('Data Exporter'),
			icon: 'fa-nav-cloud-download',
			url: `${dataExporterBaseUrl}/${pId}/list/`,
			routeMatcher: [`${dataExporterBaseUrl}/${pId}/.*$`],
			visible: ({ flagsCC, permittedActions }) => {
				return flagsCC.checkState('plat_data_exporter').then((status) => {
					if (status) {
						const dataImportActions = permittedActions['dataImports'];
						return (dataImportActions && dataImportActions.includes('platformDataExport')) || idmAdministrator;
					}
					return false;
				});
			}
		}, {
			title: i18n('Resource Library'),
			icon: 'fa-li-hand-papers',
			routeMatcher: [`^/${pId}-resource-library`],
			visible: ({ flagsCC }) => flagsCC.checkState('sk_resource_library_sidenav_update'),
			// EXAMPLE VISIBLE FUNCTIONS
			// visible: ({permittedActions}) => permittedActions['groupName'].includes('itemName'),
			children: [
				{
					title: i18n('Library'),
					children: [
						{
							title: i18n('Browse Library'),
							routeMatcher: [`^/${pId}-resource-library/library`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/library`,
						},
						{
							title: i18n('My Groups'),
							routeMatcher: [`^/${pId}-resource-library/groups`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/groups`,
						},
						{
							title: i18n('My Resource History'),
							routeMatcher: [`^/${pId}-resource-library/history`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/history`,
						}
					]
				},
				{
					title: i18n('Administration'),
					children: [
						{
							title: i18n('My Organization Resources'),
							routeMatcher: [`^/${pId}-resource-library/my-org-resources`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/my-org-resources`,
						},
						{
							title: i18n('My Resources'),
							routeMatcher: [`^/${pId}-resource-library/my-resources`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/my-resources`,
							visible: ({ flagsCC }) => flagsCC.checkState('pg_rl_user_upload'),
						},
						{
							title: i18n('Collaborative Video Rubrics'),
							routeMatcher: [`${rubricDesignerUrl}/?productId=${pId}`],
							url: `${rubricDesignerUrl}/?productId=${pId}`
						},
						{
							title: i18n('Learning Designer Admin'),
							routeMatcher: [`^/${pId}-resource-library/admin/learning-designer`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/admin/learning-designer`,
							visible: ({ flagsCC }) => flagsCC.checkState('pg_rl_learning_designer'),
						},
						{
							title: i18n('Reports'),
							routeMatcher: [`^/${pId}-resource-library/admin/reports`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/admin/reports`,
							visible: ({ flagsCC }) => flagsCC.checkState('pg_rl_reports'),
						},
						{
							title: i18n('Manage Content Curator'),
							routeMatcher: [`^/${pId}-resource-library/manage-privileges`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/manage-privileges`,
						},
						{
							title: i18n('Micro-Credential Assessor Tool'),
							routeMatcher: [`^/${pId}-resource-library/mc-assessor`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-resource-library/mc-assessor`,
						},
					]
				}
			]
		},
		{
			title: i18n('User Configuration'),
			icon: 'fa-li-users',
			routeMatcher: [`${permissionServiceBaseUrl}`],
			visible: ({ permittedActions }) => {
				if (envName !== 'prod') {
					const allIncludedAction = permittedActions[
						'dataImports'
					].filter((key) => !platPermissionToExclude.includes(key));
					let authorized = allIncludedAction.length > 0;
					return idmAdministrator || authorized;
				}
				return false;
			},
			children: [
				{
					title: i18n('Frontline User Permissions'),
					routeMatcher: [`${permissionServiceBaseUrl}/${pId}/.*$`],
					url: `${permissionServiceBaseUrl}/${pId}/userGroups`,
				},
			],
		}, {

			title: i18n('Core Configuration'),
			icon: 'fa-nav-cog',
			routeMatcher: [`^/${pId}-settings/permissions/profiles/all`, `^/${pId}-settings/hcm-core-config/*`],
			visible: ({ flagsCC }) => {
				return flagsCC.checkState('hcm_core_configuration').then(coreConfigFlagStatus => coreConfigFlagStatus);
			},
			children: [
				{
					title: i18n('Organization & Access'),
					children: [
						{
							title: i18n('HCM Permissions'),
							routeMatcher: [`^/${pId}-settings/permissions/.*$`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-settings/permissions/profiles/all`,
							visible: ({ permittedActions }) => {
								const permissionProfilesActionList = 'permissionProfiles';
								const validPermissionProfileActions = ['create', 'edit', 'delete'];
								return validPermissionProfileActions.some(action => permittedActions[permissionProfilesActionList].includes(action));
							}
						}
					],
				}, {
					title: i18n('HCM Core Configuration'),
					children: [
						{
							title: i18n('Sites/Locations'),
							routeMatcher: [`^/${pId}-settings/hcm-core-config/sites$`],
							url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-settings/hcm-core-config/sites`,
							visible: ({ permittedActions }) => {
								const permissionProfilesActionList = 'locations';
								const validPermissionProfileActions = ['create', 'edit', 'delete'];
								return validPermissionProfileActions.some(action => permittedActions[permissionProfilesActionList].includes(action));
							}
						}
					]

				}
			]
		}
	];

	let employee;
	if (idmData.identities) {
		employee = idmData.identities.find(x => x.Type === 'employee' || x.Type === 'EmployeeQA');
	}

	if (employee || idmAdministrator) {

		if (idmAdministrator || canViewOthers) {
			platformNavAll.unshift({
				title: i18n('Staff Directory'),
				icon: 'fa-nav-users',
				routeMatcher: [`^\/${pId}-employee\/(?!(?:reports)).*$`],
				url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-employee/`,
				visible: ({ flagsCC }) => flagsCC.checkState('global_fc_shared_employee')
			});
		} else {
			platformNavAll.unshift({
				title: i18n('My Staff Profile'),
				icon: 'fa-nav-user-circle',
				routeMatcher: [`^/${pId}-employee/record/.*$`],
				url: `${urlIsUiModule ? '' : ssBaseUrl}/${pId}-employee/record/${employee.Id}/personal`,
				visible: ({ flagsCC }) => flagsCC.checkState('global_fc_shared_employee')
			});
		}
	}

	/**
	 * This function determines whether user has access to the `WageStream` menu item.
	 * It fetches the role types(CSV) from configcat using feature key `idm_role_types_for_partners`
	 * and matches with `identities` and `roles` from Sidekick store of `idm-data` and `roles`.
	 * It also matches the whitelisted apps for which the `WageStream` access is provided using
	 * `WHITELISTED_WAGESTREAM_PRODUCTS` defined above
	 */
	store.onValue('roles', (rolesData) => {
		featureFlagsCC.getValue('idm_role_types_for_partners').then((roleTypes) => {
			const isVendorAccessible = roleTypes.split(',')
				.some((roleType) => idmData.identities
					&& idmData.identities.find((identity) => identity.Type === roleType
						&& rolesData.roles.some((role) => role.id === identity.Id)));
			if (isVendorAccessible && WHITELISTED_WAGESTREAM_PRODUCTS.includes(idmData.productId)) {
				platformNavAll.push({
					title: 'Wagestream',
					icon: 'fa-nav-wagestream',
					onClick: () => handleVendorNavItemClick('wagestream', orgId, idm),
					visible: ({ flagsCC }) => {
						return flagsCC.checkState('idm_show_ui_partners').then((status) => {
							if (status) {
								const storedHasAccess = persist.get('wagestream-access');
								const currentAccessToken = getAccessToken();
								if (!storedHasAccess || currentAccessToken !== storedHasAccess.token) {
									return new Promise((resolve) => {
										httpGet(`${vendorSSOApiUrl}/api/v1/partners/wagestream/userAccess?orgId=${orgId}`, function (res) {
											persist.set('wagestream-access', { token: currentAccessToken, hasAccess: !!(res && res.hasAccess) }, { type: 'local', expires: 1440 });
											resolve(!!(res && res.hasAccess));
										});
									});
								}
								return storedHasAccess.hasAccess;
							}
							return false;
						});
					}
				});
			}
		});
	});

	// get this users permitted actions so that we can personalize the extended nav
	httpGet(`${ecSand}/PermissionAPI/api/ActionPermissions?organizationId=${orgId}`, function (res) {
		const permittedActions = glean(res, x => x.data.attributes.objects, [])
			.reduce((acc, o) => Object.assign(acc, { [o.type]: o.permittedActions.map(act => toCamelCase(act)) }), {});

		// An array to hold the results of checkState promise along with the menu item associated with it
		const visiblePromises = [];

		// Resolves the checkState functions by calling item.visible recursively and storing each promise
		// with the item in an object into the visiblePromises array above
		const resolveMenuAccess = menu => {
			menu.forEach((item, idx) => {
				if (item.visible) {
					const visiblePromise = item.visible({
						permittedActions,
						flagsCC: featureFlagsCC
					});

					visiblePromises.push({
						item,
						visiblePromise
					});
				}

				if (item.children) {
					resolveMenuAccess(item.children);
				}
			});
		};

		// remove everything this user doesn't have access to, using the hasVisibility that was set
		// to the resolved value of the checkState promise
		const sanitizeMenu = menu => {
			menu.forEach((item, idx) => {
				// if this item has a permission function that returns false (no-access) we
				// should remove the item, otherwise, leave it alone
				const hasAccessToItem = glean(item, i => (i.visible == null || i.hasVisibility === true), false);

				if (!hasAccessToItem) {
					delete menu[idx];
				} else if (item.children) {
					// if this item has children, sanitize the submenu
					item.children = sanitizeMenu(item.children);

					// if all the children have been removed, there's no need to keep the parent
					if (item.children.length === 0) {
						delete menu[idx];
					}
				}
			});

			// return the menu with deleted items removed
			return menu.filter(item => item !== undefined);
		};

		// Gather all of the promises
		resolveMenuAccess(platformNavAll);

		// Wait until all checkState promises have resolved
		Promise.all(visiblePromises.map(vp => vp.visiblePromise)).then((visibleSettings) => {
			// For each item that had a promise, set hasVisibility to the resolved value of the checkState promise
			visiblePromises.forEach((vp, idx) => {
				vp.item.hasVisibility = visibleSettings[idx];
			});
		}).then(() => {
			// Finally sanitize the menu and set it to the store
			const personalizedNav = sanitizeMenu(platformNavAll);
			store.set('_platform-nav-items', personalizedNav);
		});
	});
}

// function hasPermittedAction(group, item) {
//     return ({permittedActions}) => permittedActions[group].includes(item);
// }
