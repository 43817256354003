import * as events from './event-bus';
import * as env from './env';
import * as store from './store';
import CryptoJS from 'crypto-js';

let user = null;
let apiEndpoint = null;
let allFlags = null;

const envEndpointMap = {
	dev: 'https://featureflag-dev-api.ss.frontlineeducation.com',
	qa: 'https://featureflag-qa-api.ss.frontlineeducation.com',
	stage: 'https://featureflag-stage-api.ss.frontlineeducation.com',
	prod: 'https://featureflag-api.frontlineeducation.com'
};

// this function generates a hmac token and timestamp
function generateHmacToken(secretKey, data) {
    const timestamp = Math.floor(Date.now() / 1000);
    const payload = `${data}:${timestamp}`;
    const hmac = CryptoJS.HmacSHA256(payload, secretKey).toString(CryptoJS.enc.Hex);
    return { hmac, timestamp };
}

const apiRequest = (url, body) => {
	// get secret key injected by bundle-sidekick.js
	const secretKey = process.env.HMAC_SECRET_KEY;
	const { hmac, timestamp } = generateHmacToken(secretKey, body);

    return fetch(url, {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'Authorization': `${hmac}`
		},
		body: JSON.stringify({ data: body, timestamp })
	}).then((res) => {
		return res.json();
	}).catch(() => {
		if(body.defaultValue){
			return {
				value: body.defaultValue
			};
		}else{
			return [];
		}
	});
};

export function setupPassportFeatureFlagsCC(context) {
	const { userInfo } = context;

	// bypass feature flag lookup if domain context is unavailable (used below)
	// context can be unavailable within My Frontline
	if (!userInfo.domain) {
		return;
	}

	const envName = env.environmentFromPassportDomain(window.location.host);
	apiEndpoint = envEndpointMap[envName];

	// Configcat setup based on https://www.configcat.com/docs/sdk-reference/js/
	user = {
		'identifier': userInfo.person.id,
		'custom' : {
			'orgId': userInfo.domain.id
		}
	};

	apiRequest(`${apiEndpoint}/api/v1/featureFlag/getAll`, {
		user
	}).then((response) => {
		allFlags = response;
		events.emit('feature-flags-ready-cc', { checkState, checkStateByOrg, forceRefresh, getValue });
	});
}

export function setupFeatureFlagsCC(idmData) {
	const idm = store.get('idm');
	const envName = env.environmentFromIdmGatewayUrl(idm.idGatewayUrl);
	apiEndpoint = envEndpointMap[envName];

	// configcat setup based on https://www.configcat.com/docs/sdk-reference/js/
	user = {
		'identifier': idm.flId, //TODO- replace employeeId with personId
		'custom' : {
			'orgId': idmData.userContext.OrganizationId
		}
	};

	apiRequest(`${apiEndpoint}/api/v1/featureFlag/getAll`, {
		user
	}).then((response) => {
		allFlags = response;
		events.emit('feature-flags-ready-cc', { checkState, checkStateByOrg, forceRefresh, getValue });
	});
}

/**
 * @param {*} featureKey - flag to check (key)
 * @param {*} featureValue - Optional, for comparing multivariate values
 * If key doesn't exist, configCatClient will return a promise that resolves to false as value of featureKey
 * @returns If key does exist, will return a promise that resolves to whether it matches featureValue
 */
export const checkState = (featureKey, featureValue = true) => {
	const defaultValue = false;
	const flag = allFlags.find(o => o.key === featureKey);
	if(flag && flag.value){
		return Promise.resolve(flag.value === featureValue);
	}else{
		return Promise.resolve(defaultValue === featureValue);
	}
};

/**
 * @param {*} featureKey - flag to check (key)
 * @param {*} featureValue - Optional, for comparing multivariate values
 * If key doesn't exist, configCatClient will return a promise that resolves to false as value of featureKey
 * @param {*} customUser - an object in the format of {'custom': { 'orgId': <org_id> }}.
 * Using this parameter, status of flag for a specific org is fetched.
 * If not given, default user object will be used which is defined above
 * @returns If key does exist, will return a promise that resolves to whether it matches featureValue
 */
export const checkStateByOrg = (featureKey, featureValue = true, customUser = user, defaultValue = false) => {
	return apiRequest(`${apiEndpoint}/api/v1/featureFlag`, {
		key: featureKey,
		defaultValue,
		user: customUser
	}).then((response) => {
		return response.value === featureValue;
	});
};

/**
 * This method forces the configcat client to update the config.json which contains the updated flag value.
 * This method can be used by applications which update the configcat features flags from UI and fetches the updated value without reloading the page.
 * It has been decided to not allow applications to do forceRefresh and hence empty method body
 */
export const forceRefresh = () => {};

/**
 * @param {*} featureKey - flag to check (key)
 * If key doesn't exist, configCatClient will return a promise that resolves to null as value of featureKey
 * @returns If key does exist, will return the value
 */
export const getValue = (featureKey) => {
	const defaultValue = null;
	const flag = allFlags.find(o => o.key === featureKey);
	if(flag && flag.value){
		return Promise.resolve(flag.value);
	}else{
		return Promise.resolve(defaultValue);
	}
};

//TODO- get and patch functions?
