// This file hooks up getting any extra data required for Passport
// And then calls the API for fetching the authprofile data
import * as store from './store';
import * as events from './event-bus';
import { setStateFromContext, endImpersonation, getUserContext, handleInactivity, setUserContext, getExternalRoles } from './passport-helpers';

// temporary backwards compatibility
// todo: remove once existing apps transition to new store
store.onValueOnce('morpheus', (data) => store.set('passport', data));
store.onValueOnce('morpheus-data', (data) => store.set('passport-data', data));
store.onValueOnce('passport', (data) => getExternalRoles(data));

store.onValueOnce('passport', getUserContext);
store.onValueOnce('passport', handleInactivity);
store.onValueOnce('passport', () => events.emit('session.passport.loggedIn'));
store.onValue('passport-data', setStateFromContext);
store.onValueOnce('passport-data', registerPassportEvents);

/**
 * registerPassportEvents()
 * This method registers the events when passport-data is stored. It prevents the events from registering in case of idm-data
 */
function registerPassportEvents() {
	// handle changing domain
	events.on('header.org-switcher.change', function (selectedOrg) {
		const { userInfo, authProfile } = store.get('passport-data');
		const selectedDomain = authProfile.domains.find(domain => domain.id === selectedOrg.id);
		const currentApp = selectedDomain.applications.find(app => app.shortName === userInfo.application.shortName);

		// application does not exist in selected domain
		if (!currentApp) {
			const access = [];
			selectedDomain.applications.forEach(app => {
				access.push({
					Disabled: false,
					OrganizationId: selectedDomain.id,
					OrganizationName: selectedDomain.name,
					ProductId: app.shortName,
					ProductName: app.name,
					Url: app.address,
				});
			});

			// launch application picker
			// todo: add ability to pick role as well?
			return store.set('pick-org-app-data', Object.assign({}, {
				orgId: selectedOrg.id,
				productId: userInfo.application.shortName,
				access,
			}));
		}

		// User has access to current app in selected domain
		window.location.href = `${currentApp.address}?!domain=${selectedDomain.name}`;
	});

	// handle app selection in app switcher
	events.on('_pick-org-app.selected', function ({ orgId, productId }) {
		const { authProfile } = store.get('passport-data');
		const selectedDomain = authProfile.domains.find(domain => domain.id === orgId);
		const currentApp = selectedDomain.applications.find(app => app.shortName === productId);

		window.location.href = `${currentApp.address}?!domain=${selectedDomain.name}`;
	});

	// handle changing role
	events.on('header.user-info.role', role => {
		setUserContext({ role: role.name });
	});

	// handle My Frontline click Also handles Logo Click
	// link should only exist if user has more than one app/role
	events.on('header.user-info.frontline-home', () => {
		const { passportBaseUrl } = store.get('passport-data');
		window.location.href = `${passportBaseUrl}/app`;
	});

	// handle end of impersonation
	events.on('header.user-info.end-impersonation', endImpersonation);

	// handle account settings click
	events.on('header.user-info.account-settings', () => {
		const { passportBaseUrl, userInfo } = store.get('passport-data');
		const fromUrl = encodeURIComponent(window.location);
		const fromApp = userInfo && userInfo.application ? encodeURIComponent(userInfo.application.name) : '';

		window.location.href = `${passportBaseUrl}/account/settings?fromUrl=${fromUrl}&fromApp=${fromApp}`;
	});

	// handle logout event
	events.on('_sk-logout', () => {
		const { passportBaseUrl } = store.get('passport-data');
		window.location.href = `${passportBaseUrl}/logout`;
	});

	// handle session.logout.timeout event when emitted due to `session-timeout.timer-end`
	events.on('session.logout.timeout', () => {
		const { passportBaseUrl } = store.get('passport-data');
		window.location.href = `${passportBaseUrl}/logout`;
	});
	events.on('session.logout', () => {
		const { passportBaseUrl } = store.get('passport-data');
		window.location.href = `${passportBaseUrl}/logout`;
	});
}
