import * as events from '../../util/event-bus';
import * as dom from '../../util/easy-dom';
import * as store from '../../util/store';

/**
 * Container class
 * @class Sidekick.Header
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs
 * @param {Object} ctx
 * @param {Function} i18n - translation function
 */
export default function (elem, attrs, ctx, i18n) {
	const domAppEl = dom.element(store.get('sk-config').appElement);
	const { hideAppSwitcher, hideOrgSwitcher, hideSearch, customLogo, customLogoHomeUrl, responsiveTopNav, isB2C } = store.get('sk-config');
	const [domExitImpersonation] = elem.find('.sk--impersonation-exit');
	const [domPassportLogo] = elem.find('.frontline-logo');
	const [domPassportCustomLogo] = elem.find('.custom-logo');
	const domLogoElement = document.querySelectorAll('.frontline-logo');
	const domCustomLogoElement = document.querySelectorAll('.custom-logo');

	if(responsiveTopNav) {
		const [domHeader] = domAppEl.find('.sk-header');
		domHeader.css('min-width', '320px');

		if(!isB2C) {
			domLogoElement.forEach((domLogoElement) => {
				domLogoElement.classList.add('frontline-responsive-logo');
			});
			domCustomLogoElement.forEach((domCustomLogoElement) => {
				domCustomLogoElement.classList.add('no-padding');
			});
		}
	}

	events.on('header.visible', function (show) {
		domAppEl.toggleClass('sk-header-hidden', !show);
		elem.toggleClass('sk-hidden', !show);
	}, true);

	// impersonation v2 overrides regular impersonation modes
	let activelyImpersonatingV2 = false;
	let idGatewayBaseUrl = '';
	store.onValue('idm', ({idGatewayUrl, isImpersonatingV2 = false}) => {
		activelyImpersonatingV2 = isImpersonatingV2;
		idGatewayBaseUrl = (idGatewayUrl.endsWith('/')
			? idGatewayUrl.slice(0, -1)
			: idGatewayUrl
		);
		if (isImpersonatingV2) {
			events.emit('_header.impersonation', isImpersonatingV2);
		}
	}, {greedy: true});

	// set header class for passport mode to allow for separate styling
	store.onValue('passport', () => {
		domAppEl.toggleClass('sk-passport', true);
		const elements = {
			'sk-app-switcher': hideAppSwitcher,
			'sk-org-switcher': hideOrgSwitcher,
			'sk-search': hideSearch
		};

		for (const [key, value] of Object.entries(elements)) {
			if (value) {
				const element = document.querySelector(`.${key}`);
				if (element) {
					element.remove();
				}
			}
		}
		if (customLogo) {
			domPassportLogo.attr('style', 'display:none !important');
			domPassportCustomLogo.attr('style', 'display:inline-block !important');
			// if responsiveTopNav is true, add the logo-mobile class to the custom logo
			const responsiveLogoClass = responsiveTopNav ? 'logo-mobile': '';
			const domCustomLogo = `<img class="${responsiveLogoClass}" src="${customLogo}" alt="Logo" />`;
			if (!customLogoHomeUrl) {
				domPassportCustomLogo._.innerHTML = domCustomLogo;
				domPassportCustomLogo.on('click', function (evt) {
					evt.stopPropagation();
					evt.preventDefault();
					events.emit('header.user-info.frontline-home');
				});
			} else {
				domPassportCustomLogo._.innerHTML = `<a href=${customLogoHomeUrl}>${domCustomLogo}</a>`;
			}
		}

	});

	// this greedy, terminal event handler acts as a gatekeeper. If impersonation v2
	// is active, we need to ignore calls to alter impersonation mode by the application.
	events.on('header.impersonation', (state) => {
		if (activelyImpersonatingV2) { return; }
		events.emit('_header.impersonation', state);
	}, { greedy: true, terminal: true });

	// apply the class based on the internal event that is controlled by the gatekeeper
	events.on('_header.impersonation', state => domAppEl.toggleClass('sk-impersonation', state), true);

	domExitImpersonation.on('click', function (evt) {
		evt.stopPropagation();
		evt.preventDefault();
		if (activelyImpersonatingV2) {
			window.location.href = `${idGatewayBaseUrl}/impersonate/v2/user/end`;
		} else {
			events.emit('header.end-impersonation');
		}
	});
	// adding the click of fl logo button in morpheus
	domPassportLogo.on('click', function(evt) {
		evt.stopPropagation();
		evt.preventDefault();
		events.emit('header.user-info.frontline-home');
	});
};
