/* globals ambient */
import loadPlatformNav from './nav-platform';
import * as dom from '../../util/easy-dom';
import * as events from '../../util/event-bus';
import * as store from '../../util/store';
import * as keyPress from '../../util/key-press-helpers';

const expandKey = 'sk-leftnav-expanded';
const storage = window.localStorage;
const animationDuration = 300;

/**
 * Top level navigation
 * @class Sidekick.Navigation
 * @memberOf Sidekick
 * @param {EasyDom} elem - Container {@link EasyDom} element
 * @param {Object} attrs - Any attributes present on the "elem" dom node
 * @param {Object} ctx - Context object containing nav data
 * @param {Function} i18n - translation function
 */
export default function(elem, attrs, ctx, i18n) {
	loadPlatformNav(elem, i18n);
	var autoCollapseNav = ambient.isActive('sk-auto-collapse-nav');
	var currentPath = '';
	const appCfg = store.get('sk-config');

	// stores the expanded state of the user preference
	let expandState = storage.getItem(expandKey) !== 'false';

	/////
	// dom elements we require access to
	//
	const domAppEl = dom.element(appCfg.appElement);
	const domBody = dom.element(document.body);
	const [domDimmer] = elem.find('.sk--content-dimmer');
	const [domNavArrowIcon] = elem.find('.sk--nav-arrow');
	const [domNavArrowTooltip] = elem.find('.sk--nav-arrow-tooltip');
	const [domTopSection] = elem.find('.sk--top-section');
	const [domBottomSection] = elem.find('.sk--bottom-section');
	const [domOrgBrandSmall] = elem.find('.sk--org-brand-small');
	const [domOrgBrandLarge] = elem.find('.sk--org-brand-large');
	const [domFlBrandLarge] = domBottomSection.find(`.sk--full-logo > a`);
	const [domFlBrandSmall] = domBottomSection.find(`.sk--small-logo > a`);

	/////
	// init styles
	//

	/////
	// register event listeners and handlers
	//
	domTopSection.on('click', (evt) => evt.stopPropagation());
	domBottomSection.on('click', (evt) => evt.stopPropagation());
	domBody.on('click', closeSubMenu);
	domDimmer.on('click', () => autoCollapseNav ? toggleNavExpanded(false) : closeSubMenu());
	events.on('sk-menu-open', function (menu) {
		if (menu === null) { return; }
		if (autoCollapseNav) { toggleNavExpanded(false); }
		closeSubMenu();
	});

	store.onValueOnce('org-branding-data', function (branding) {
		if (branding == null) { return; }
		const {rectangleImagePublicUrl, squareImagePublicUrl} = branding;
		if (rectangleImagePublicUrl == null) { return; }
		if (squareImagePublicUrl == null) { return; }

		domOrgBrandSmall.css('background-image', `url(${squareImagePublicUrl})`);
		domOrgBrandLarge.css('background-image', `url(${rectangleImagePublicUrl})`);
		elem.addClass('sk--has-org-branding');
	});

	events.on('nav.menu-item-clicked', handleMenuClick);
	events.on('nav.plat-menu-item-clicked', handleMenuClick);
	function handleMenuClick(item) {
		if (!item.onClick && !(item.children && item.children.length)) {
			dismissVendorOverlay();
		}
		// clear focus from item clicked
		if (document.activeElement) { document.activeElement.blur(); }
		// handle NAV collapsing after menu click
		if (autoCollapseNav) {
			toggleNavExpanded(false);
		} else {
			closeSubMenu();
		}
	}

	events.on('nav.visible', function(show) {
		domAppEl.toggleClass('sk-nav-hidden', !show);
	}, true);
	function handleArrowToggle(evt) {
		if (!autoCollapseNav) {
			expandState = !domBody.hasClass('sk-nav-expanded');
			storage.setItem(expandKey, expandState);
			events.emit('_nav.expand-arrow', expandState);
		}
		toggleNavExpanded();
	}
	domNavArrowIcon.on('click', (evt) => {
		handleArrowToggle(evt);
		// evt.target.blur();
	});
	domNavArrowIcon.on('keydown', (evt) => {
		switch(true) {
		case keyPress.isEnter(evt):
		case keyPress.isSpace(evt):
			handleArrowToggle(evt);
			break;
		}
	});
	ambient.on('enter', 'sk-auto-collapse-nav', () => setAutoCollapseNav(true));
	ambient.on('leave', 'sk-auto-collapse-nav', () => setAutoCollapseNav(false));
	// setInterval is the most reliable way to handle all path changes ¯\_(ツ)_/¯
	setInterval(checkForPathChange, 500);

	// listen for idm-data for a user preference; update local variable and storage if found
	store.onValueOnce('idm-data', data => {
		if (data.session.State == null || data.session.State.prefLeftNavExpanded == null) { return; }
		expandState = data.session.State.prefLeftNavExpanded;
		storage.setItem(expandKey, expandState);
		if (!autoCollapseNav) {
			toggleNavExpanded(expandState);
		}
	});

	domBody.addClass('sk-nav-submenu-collapsed');
	if (autoCollapseNav) { elem.addClass('sk-nav-collapsed'); }

	if (ctx.menuItems == null) { return; }

	// if extended nav data is not loaded yet, wait for data and re-render nav
	let extendedNav = store.get('_platform-nav-items');
	// if the extended nav isn't available yet, setup another render call for when it shows up
	if (extendedNav == null) {
		store.onValueOnce('_platform-nav-items', eNav => renderMenu(eNav, true));
	}
	// render the menu NOW because we want to show it as soon as possible
	renderMenu(extendedNav || []);

	function renderMenu(platformNav, kickCycle = false) {
		const [menuContainer] = elem.find('.sk--menu-items');

		const appMenuItems = ctx.menuItems.map(m => Object.assign(m, {isPlatformMenu: false, baseUrl: ctx.baseUrl}));
		const menuDivider = platformNav.length ? ['-'] : [];
		const platformMenuItems = platformNav.map(m => Object.assign(m, {isPlatformMenu: true}));

		const mainMenuItems = [
			...appMenuItems,     // product nav
			...menuDivider,      // ------------
			...platformMenuItems // platform nav
		].map(function (item, idx) {
			if (item === '-') {
				return dom.parse(`<li class="sk--menu-divider"></li>`);
			}
			// create the base element
			const el = dom.parse(`<li sk-nav-item></li>`);

			// add menuName value if present
			if (typeof item.menuName === 'string') {
				el.data('menu-name', item.menuName);
			}

			// attach context item for component rendering
			el._.context = item;

			// after the next sidekick cycle processes this menu item, hook it
			// up to handle flyouts and menu actions
			dom.rAF(function () {
				const domContainer = el.find('.sk--main-menu-item-container')[0];

				if (item.onClick) {
					domContainer.on('click', evt => {
						evt.preventDefault();
						item.onClick();
					});
				}

				if (!item.url || (item.children && item.children.length)) {
					domContainer.on('click', evt => evt.preventDefault());
				}

				if (item.children && item.children.length) {
					domContainer.on('click', () => menuHasAttention(el));
				} else {
					domContainer.on('click', closeSubMenu);
				}
			});

			return el;
		});
		// add the menu items to the DOM
		menuContainer.empty().append(mainMenuItems);
		if (kickCycle) { window.sidekick.cycle(); }

		dom.rAF(() => {
			let val = (
				domTopSection._.getBoundingClientRect().bottom
				+ domBottomSection._.getBoundingClientRect().height
				- elem._.getBoundingClientRect().top
			);
			elem.css('min-height', `${val}px`);
		});
	}

	if (!autoCollapseNav) {
		toggleNavExpanded(expandState);
	}

	/////
	// helper functions
	//
	// compare the current path to the location and emit an event if path has changed
	function checkForPathChange() {
		if (currentPath === window.location.href) { return; }
		const previousPath = currentPath;
		currentPath = window.location.href;
		const host = window.location.host;
		const path = currentPath.substring(currentPath.indexOf(host) + host.length);

		events.emit('_location.path-change', path, currentPath, previousPath);
	}

	var expandedFootprint = null;

	function toggleNavArrowTooltipContent(open) {
		const navArrowContent = i18n(open ? 'Minimize Navigation' : 'Expand Navigation');
		domNavArrowTooltip.attr('content', navArrowContent);
		dom.rAF(function () {
			const tipContent = domNavArrowTooltip.find('.sk-tip-content')[0];
			if (tipContent != null) {
				tipContent._.textContent = navArrowContent;
			}
		});
	}

	function toggleNavExpanded(open) {
		if (open == null) { open = !domBody.hasClass('sk-nav-expanded'); }

		const newExpandedFootprintState = open && !autoCollapseNav;
		if (newExpandedFootprintState !== expandedFootprint) {
			events.emit('nav.expanded-footprint', newExpandedFootprintState);
			expandedFootprint = newExpandedFootprintState;
		}

		if (open) {
			events.emit('sk-menu-open', null); // close other menus
			elem.removeClass('sk-nav-collapsed'); // the nav is about to NOT be collapsed
		}

		toggleNavArrowTooltipContent(open);

		// allow the sk-nav-collapsed class change to take effect before making
		// more DOM changes, this makes css animations work correctly
		dom.rAF(function () {
			// if there is a submenu open, close it while we toggle the nav state
			if (domBody.hasClass('sk-nav-submenu-active')) { closeSubMenu(); }
			// add/remove sk-nav-expanded class if we are opening
			domBody.toggleClass('sk-nav-expanded', open);

			// if we are closing, we need to add the sk-nav-collapsed class after the
			// css animation finishes
			if (!open) {
				setTimeout(() => {
					if (!domBody.hasClass('sk-nav-expanded')) {
						elem.addClass('sk-nav-collapsed');
					}
				}, animationDuration);
			}

			// toggle the tabindex of the logo links according to nav expanded state
			// (preferred due to the animation)
			setTimeout(() => {
				domFlBrandLarge.attr('tabindex', open ? null : '-1'); // only tabable when open
				domFlBrandSmall.attr('tabindex', open ? '-1' : null); // only tabable when shut
			}, animationDuration);
		});
	}

	function closeSubMenu() {
		domBody.removeClass('sk-nav-submenu-active');
		dom.find('.sidekick.sk-nav .sk-submenu-active').forEach(m => m.removeClass('sk-submenu-active'));
		// add the sk-nav-submenu-collapsed class after the css animation finishes
		setTimeout(() => domBody.addClass('sk-nav-submenu-collapsed'), animationDuration);
	}

	function setAutoCollapseNav(state) {
		autoCollapseNav = state;
		if (state) {
			toggleNavExpanded(false);
		} else {
			toggleNavExpanded(expandState);
		}
	}

	function dismissVendorOverlay() {
		if (window.vendorSSO) {
			setTimeout(() => {
				window.vendorSSO.destroy();
			}, 500);
		}
	}

	// the menu item gains attention
	function menuHasAttention(menu) {
		events.emit('sk-menu-open', null); // close other menus
		const [subMenu] = menu.find('.sk-nav-item-mega-menu,.sk-nav-item-submenu');
		subMenu.on('keydown', (evt) => {
			switch(true) {
			case keyPress.isEscape(evt):
				subMenu.removeClass('sk-submenu-active');
				menu.removeClass('sk-submenu-active');
				const [link] = menu.find('a');
				if (link != null) {
					link._.focus();
				}
				evt.stopPropagation();
				break;
			}
		});
		dom.rAF(function () {
			domBody.removeClass('sk-nav-submenu-collapsed');
			dom.rAF(function () {
				menu.toggleClass('sk-submenu-active');
				if (menu.hasClass('sk-submenu-active')) {
					// make sure the global context reflects that there is a menu open
					domBody.addClass('sk-nav-submenu-active');
					if (subMenu != null) {
						subMenu._.focus();
					}

					// if there's another menu open, close it
					dom.find('.sidekick.sk-nav .sk-submenu-active')
						.filter(el => el !== menu)
						.forEach(el => el.removeClass('sk-submenu-active'));
				} else {
					closeSubMenu();
				}
			});
		});
	}
};
