import * as store from '../../util/store';
import * as events from '../../util/event-bus';
import { loadAudioEye } from '../../util/audioeye-helpers';

export default function () {
    let featureFlagsCC = null;
    let idmData = store.get('idm');
    events.once('feature-flags-ready-cc', flagsSvc => {
        featureFlagsCC = flagsSvc;
        featureFlagsCC.checkStateByOrg('audioeye_enabled', true, { 'custom': { 'productId': idmData.product } }, 'false').then((res) => {
            if (res) {
                loadAudioEye();
            }
        });
    }, { greedy: true });

};
